<template>
    <BaseCard class="col-span-2">
        <template #title>
            Globale Buchungssuche
        </template>
        <template #default>
            <div>
                <div class="m-4">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText
                            v-model="search"
                            placeholder="Search"
                        />
                    </span>
                </div>
                <DataTable
                    v-if="bookings.data"
                    :value="bookings.data.value"
                    table-style="min-width: 50rem"
                    paginator
                    :loading="bookings.isLoading.value"
                    :rows="5" 
                >
                    <Column
                        class="overflow-hidden text-ellipsis"
                        field="unique_id"
                        header="Id"
                    >
                        <template #body="{data}">
                            <a :href="data['link']">
                                <PButton
                                    :label="data['unique_id']"
                                />
                            </a>
                        </template>
                    </Column>
                    <Column
                        class="overflow-hidden text-ellipsis"
                        field="display_name"
                        header="Titel"
                    />
                    <Column
                        sortable
                        field="date"
                        header="Eingangsdatum"
                    />
                    <Column
                        sortable
                        field="booking_date"
                        header="Buchungsdatum"
                    />
                    <Column
                        field="requester"
                        header="Budget"
                    />
                    <Column
                        sortable
                        class="text-right"
                        field="booked_total"
                        header="Betrag"
                    >
                        <template
                            #body="slotProps"
                        >
                            <EuroAmount
                                :amount="parseFloat(slotProps.data.booked_total)"
                            />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </template>
    </BaseCard>
</template>

<script setup lang="ts">
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import EuroAmount from '@/components/EuroAmount.vue';
import { useBackend } from '@/composables/useBackend';
import { ref } from 'vue';

const { getBookings } = useBackend() 

const search = ref('')
const bookings = getBookings(search)
</script>