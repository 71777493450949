<template>
    <tr
        class="hover:bg-gray-100 border-t"
        :class="isBudgetReceiver ? 'border-black' : 'border-gray-400 border-dotted'"
        :cy-budgettreetablerow-tr="budgetPk"
    >
        <td>
            <div class="flex items-center gap-2">
                <PButton
                    :style="'margin-left: ' + depth + 'rem'"
                    :icon="isExpanded ? 'pi pi-angle-down' : 'pi pi-angle-right'"
                    rounded
                    text
                    :cy-budgettreetablerow-pbutton-expand="budgetPk"
                    @click="isExpanded = !isExpanded"
                />
                <span :cy-budgettreetablerow-span-name="budgetPk">{{ budget.name }}</span>
            </div>
        </td>
        <td class="max-w-min">
            <Tag
                v-if="!isBudgetReceiver && (budget as components['schemas']['YearBudget']).status"
                class="text-right whitespace-nowrap"
                :class="[(budget as components['schemas']['YearBudget']).status.progress < 0.3 ? '!bg-tum-orange-danger': '!bg-tum-green-dark']"
                :value="(budget as components['schemas']['YearBudget']).status.text"
                :cy-budgettreetablerow-tag-status="budgetPk"
            />
        </td>
        <td class="max-w-min">
            <Tag
                v-if="(budget as components['schemas']['YearBudget']).has_request"
                class="text-right !bg-tum-blue-dark-5"
                value="Antrag"
                :cy-budgettreetablerow-tag-request="budgetPk"
            />
            <Tag
                v-else
                class="text-right !bg-tum-blue-light"
                value="Budget"
                :cy-budgettreetablerow-tag-budget="budgetPk"
            />
        </td>
        <td class="text-right">
            <EuroAmount :amount="parseFloat(budget.actual)" />
            <br>
            <EuroAmount
                v-if="budget.actual != budget.expected"
                planned
                :amount="parseFloat(budget.expected)"
            />
        </td>
        <td>/</td>
        <td class="max-w-min text-right">
            <EuroAmount
                :cy-budgettreetablerow-euroamount-target="budgetPk"
                :amount="parseFloat(budget.target)"
            />
            <br>
        </td>
        <td class="max-w-min text-right">
            <EuroAmount :amount="parseFloat(budget.actual) - parseFloat(budget.target)" />
            <br>
            <EuroAmount
                v-if="budget.actual !== budget.expected && budget.target"
                planned
                :amount="parseFloat(budget.expected) - parseFloat(budget.target)"
            />
        </td>
        <td>
            <div class="flex">
                <PButton
                    v-if="!(budget as components['schemas']['YearBudget']).has_request"
                    v-tooltip="'Subbudget erstellen'"
                    rounded
                    text
                    :cy-budgettreetablerow-create-budget="budgetPk"
                    @click="openCreateBudgetModal"
                >   
                    <template #icon>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-5 w-5"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                            />
                        </svg>
                    </template>
                </PButton>
                <PButton
                    v-else-if="loggedInUser?.user.is_superuser"
                    v-tooltip="'Zu den Anträgen'"
                    href
                    rounded
                    text
                    @click="$router.push({ name: 'request.vue'})"
                >   
                    <template #icon>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                            />
                        </svg>
                    </template>
                </PButton>
                <PButton
                    v-if="!isBudgetReceiver && !(budget as components['schemas']['YearBudget']).has_request"
                    v-tooltip="'Budget bearbeiten'"
                    rounded
                    text
                    severity="danger"
                    @click="openEditBudgetModal"
                >
                    <template #icon>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-5 w-5"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                        </svg>
                    </template>
                </PButton>
                <PButton
                    v-if="false && depth > 0"
                    rounded
                    text
                    severity="danger"
                    @click="deleteBudget"
                >
                    <template #icon>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-5 w-5"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19.5 12h-15"
                            />
                        </svg>
                    </template>
                </PButton>
            </div>
        </td>
    </tr>
    <template v-if="isExpanded">
        <template v-if="yearBudget">
            <BudgetTreeTableRow
                v-for="b in yearBudget.budgets"
                :key="b.pk"
                :depth="depth + 1"
                :budget="b"
                :parent-name="budget.name"
                :requester-pk="isBudgetReceiver ? (budget as components['schemas']['YearBudgetReceiver']).requester : requesterPk"
                @update:budget="updateBudget"
            />
        </template>
        <tr>
            <td
                colspan="8"
                class="pr-0"
            >
                <RequestTable
                    v-if="yearBudget && yearBudget.bookings && yearBudget.bookings.length > 0"
                    :style="'margin-left: ' + depth + 'rem'"
                    :bookings="yearBudget.bookings"
                    :parent-pk="isBudgetReceiver ? undefined : budgetPk"
                    :parent-name="budget.name"
                    :requester-pk="isBudgetReceiver ? (budget as components['schemas']['YearBudgetReceiver']).requester : requesterPk"
                    @save="updateBudget"
                    @update="updateBudget"
                />

                <Skeleton
                    v-else-if="isLoading"
                    height="2rem"
                    border-radius="16px"
                />
                <div
                    v-else
                    class="text-gray-500 flex items-center gap-2"
                    :style="'margin-left: ' + (depth + 1) + 'rem'"
                >
                    <template v-if="!(budget as components['schemas']['YearBudget']).has_request">
                        <PButton
                            v-tooltip="'Request erstellen'"
                            rounded
                            text
                            @click="isRequestDialogVisible = true"
                        >
                            <template #icon>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="h-5 w-5"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                            </template>
                        </PButton>
                        Es sind keine Buchungen vorhanden!
                    </template>
                    <template v-else>
                        <!-- TODO: Button um Rechnung anzulegen hier -->
                        Es sind keine Buchungen zu diesem Antrag vorhanden!
                    </template>
                </div>
            </td>
        </tr>
    </template>
    <BudgetModal
        v-model:is-visible="isBudgetDialogVisible"
        :pk="isBudgetEdited ? budget.pk : undefined"
        :parent-pk="isBudgetReceiver || isBudgetEdited ? undefined : budgetPk"
        :parent-name="parentName"
        :requester-pk="isBudgetReceiver ? (budget as components['schemas']['YearBudgetReceiver']).requester : requesterPk"
        @save="updateBudget"
        @update="updateBudget"
    />
    <RequestModal
        v-if="!isBudgetReceiver && isRequestDialogVisible"
        v-model:is-visible="isRequestDialogVisible"
        :pk="budget.pk"
        :parent-pk="isBudgetReceiver ? budget.pk : budgetPk"
        :requester-pk="isBudgetReceiver ? (budget as components['schemas']['YearBudgetReceiver']).requester : requesterPk"
        @save="updateBudget"
    />
</template>

<script setup lang="ts">
import type { components } from '@/models/openapi'
import {
    ref, type PropType, computed, 
} from 'vue'
import Tag from 'primevue/tag';
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import Skeleton from 'primevue/skeleton'
import { useBackend } from '@/composables/useBackend'
import { useQueryClient } from '@tanstack/vue-query';

const confirm = useConfirm()
const toast = useToast()
const queryClient = useQueryClient()
const {
    getYearBudgetDetails, getLoggedInUser, 
} = useBackend()

const { data: loggedInUser } = getLoggedInUser()


const emit = defineEmits(['update:budget'])

const updateBudget = () => {
    queryClient.invalidateQueries([
        'yearBudgetDetails', budgetPk.value,
    ])
    emit('update:budget')
}

const props = defineProps({
    budget: {
        type: Object as PropType<components['schemas']['YearBudgetReceiver'] | components['schemas']['YearBudget']>,
        default: () => {
            return {}
        },
    },
    parentName: {
        type: String,
        default: '',
    },
    depth: {
        type: Number,
        default: 0,
    },
    requesterPk: {
        type: Number,
        required: true,
    },
})
const isRequestDialogVisible = ref(false)
const isBudgetDialogVisible = ref(false)
const isBudgetEdited = ref(false)
const isExpanded = ref(false)

const isBudgetReceiver = computed(() => props.depth === 0)
const budgetPk = computed(() => props.budget.pk)
const parentName = computed(() => isBudgetEdited.value ? props.parentName : props.budget.name)

const {
    data: yearBudget, isLoading, 
} = getYearBudgetDetails(budgetPk, isBudgetReceiver, isExpanded)


const openEditBudgetModal = () => {
    isBudgetEdited.value = true
    isBudgetDialogVisible.value = true
}

const openCreateBudgetModal = () => {
    isBudgetEdited.value = false
    isBudgetDialogVisible.value = true
}

 
const deleteBudget = () => {
    confirm.require({
        message: 'Willst du dieses Budget wirklich löschen?',
        header: 'Budget löschen',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => {
            toast.add({
                severity: 'info',
                summary: 'Budget gelöscht',
                detail: '',
                life: 3000, 
            })
        },
    })
}
</script>
