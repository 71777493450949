<template>
    <div>
        <table class="border-gray-400 w-full border border-dashed py-2 pl-4">
            <thead>
                <tr class="p-column-header-content whitespace-nowrap">
                    <th>
                        <PButton
                            v-tooltip="'Request erstellen'"
                            rounded
                            text
                            @click="isRequestDialogVisible = true"
                        >
                            <template #icon>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="h-5 w-5"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                            </template>
                        </PButton>
                    </th>
                    <th
                        class="p-column-title"
                        colspan="4"
                    >
                        {{ parentName }}
                    </th>
                </tr>
                <tr class="p-column-header-content">
                    <th class="p-column-title">
                        Nummer
                    </th>
                    <th>Eingangsdatum</th>
                    <th class="w-full">
                        Bezeichung
                    </th>
                    <th class="text-right">
                        Betrag
                    </th>
                </tr>
            </thead>

            <tbody>
                <template
                    v-for="booking in bookings"
                    :key="booking.pk"
                >
                    <tr
                        class="border-l-4 rounded"
                        :style="borderStyle(booking)"
                    >
                        <td>
                            <a :href="booking.link">
                                <Button
                                    :label="booking.unique_id"
                                />
                            </a>
                        </td>
                        <td>{{ useDate().format(new Date(booking.date)) }}</td>
                        <td>
                            <b v-if="booking.status.text === 'cancelled'">STORNIERT:</b>
                            {{ booking.display_name }}<br>
                            <ProgressBar
                                v-tooltip.bottom="booking.status.text"
                                :value="booking.status.progress *100"
                                class="!h-2"
                                :show-value="false"
                            />
                        </td>
                        <td class="text-right">
                            <EuroAmount :amount="parseFloat(booking.booked_total)" />
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <Suspense>
            <template #default>
                <RequestModal
                    v-if="isRequestDialogVisible"
                    v-model:is-visible="isRequestDialogVisible"
                    :parent-pk="props.parentPk"
                    :requester-pk="props.requesterPk"
                    @save="(budget: components['schemas']['BudgetReply']) => emit('create-budget', budget)"
                />
            </template>
        </Suspense>
    </div>
</template>

<script setup lang="ts">
import {
    ref, type PropType, 
} from 'vue'
import ProgressBar from 'primevue/progressbar';
import type { components } from '@/models/openapi'
import { useDate } from '@/composables/useUtils'
import Button from 'primevue/button';

const isRequestDialogVisible = ref(false)

const props = defineProps({
    bookings: {
        type: Array as PropType<components['schemas']['YearBooking'][]>,
        default: () => [],
    },
    parentPk: {
        type: Number,
        default: undefined,
    },
    parentName: {
        type: String,
        default: '',
    },
    requesterPk: {
        type: Number,
        required: true,
    },
})
const emit = defineEmits(['create-budget'])

const borderStyle = (booking: components['schemas']['YearBooking']) => {
    if (booking.unique_id[0] == 'E') {
        return 'border-color: green'
    } else if (booking.unique_id[0] == 'I') {
        return 'border-color: black'
    } else if (booking.unique_id[0] == 'B') {
        return 'border-color: blue'
    } else if (booking.unique_id[0] == 'N') {
        return 'border-color: gray'
    } else {
        return 'border-color: red'
    }
}
</script>
