import {
    createRouter, createWebHistory, type RouteRecordRaw, 
} from 'vue-router'

const pages = import.meta.glob('../src/pages/**/*.vue', { eager: true })

const routes: RouteRecordRaw[] = []

const exemptedRoutes = ['/new/unauthorized']

Object.entries(pages).forEach(([
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    key, value]: [string, any,
]) => {
    routes.push({
        path: '/new/' + key.split('/pages/')[1].split('.vue')[0].replace('index', ''),
        name: key.split('/pages/')[1],
        component: value.default,
    })
})

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
})

router.beforeEach(async (to) => {
    if (!exemptedRoutes.includes(to.path)) {
        fetch(`/api/whoami`).then((d) => d.json()).then(response => {
            if (!response.user) {
                window.location.href = `/accounts/login?next=${to.path}`
            }
        })   
    }
})

export default router
