import type { App } from 'vue'

const components = import.meta.glob('../src/components/**/*.vue', { eager: true })

export default {
    install(app: App) {
        Object.entries(components).forEach(([
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
            key, value]: [any, any,
]) => {
            const componentName = key
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
            app.component(componentName, value.default)
        })
    }, 
}
