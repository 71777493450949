<template>
    <div>
        <div class="mb-5 mx-10 flex flex-col gap-2">
            <div>
                Haushaltsjahr:
                <PDropdown
                    :model-value="year"
                    class="ml-5"
                    :options="yearOptions"
                    @update:model-value="router.push(`/new/year/${$event}/${tabIndex}`)"
                />
            </div>
        </div>
        
        <BaseCard>
            <div v-if="annualPlan && !isAnnualPlanFetching">
                <PTabView
                    class="tabview-custom"
                    lazy
                    :active-index="tabIndex"
                    @tab-change="router.push(`/new/year/${year}/${$event.index}`)"
                >
                    <PTabPanel
                        v-for="fond in annualPlan.fonds"
                        :key="fond.pk"
                    >
                        <template #header>
                            <span :cy-annualplan-tabview-fond="fond.pk">{{ fond.name }}</span>
                        </template>
                        <Suspense>
                            <template #default>
                                <BudgetTreeTable
                                    :fond-id="fond.pk"
                                    :year="year"
                                />
                            </template>
                            <template #fallback>
                                <BudgetTreeTableSkeleton />
                            </template>
                        </Suspense>
                    </PTabPanel> 
                </PTabView>
            </div>
            <div v-else>
                <BudgetTreeTableSkeleton />
            </div>
        </BaseCard>
    </div>
</template>

<script setup lang="ts">
import { useBackend } from '../../../composables/useBackend'
import {
    watch, computed, 
} from 'vue'
import {
    useRoute, useRouter, 
} from 'vue-router';

const { getAnnualPlan } = useBackend()
const route = useRoute()
const router = useRouter()

const tabIndex = parseInt(route.params.tab as string) || 0
const year = computed( ()=> parseInt(route.params.year as string) || new Date().getFullYear())

const {
    data: annualPlan, isFetching: isAnnualPlanFetching, refetch, 
} = getAnnualPlan(year.value)

const yearOptions = computed(() => {
    const years: number[] = [];
    for (let i = 2019; i <= new Date().getFullYear(); i++) {
        years.push(i)
    }
    return years
})

watch(() => route.params.year, () => refetch())
</script>
