<template>
    <div>
        <div class="flex items-center gap-5">
            <div>Name:</div>
            <InputText v-model="nameFilter" />
        </div>
        <table>
            <thead>
                <tr>
                    <th class="w-full">
                        Name
                    </th>
                    <th />
                    <th />
                    <th class="text-right">
                        Verbrauch<br><i style="text-weight: normal">erwartet</i>
                    </th>
                    <th>/</th>
                    <th class="text-right">
                        Budgetziel
                    </th>
                    <th class="whitespace-nowrap text-right">
                        Noch übrig
                    </th>
                </tr>
            </thead>
            <tbody>
                <BudgetTreeTableRow
                    v-for="budget in filteredBudgets"
                    :key="budget.pk"
                    :budget="budget"
                    :requester-pk="budget.requester"
                    @update:budget="updateFond"
                />
                <tr class="border-t-2 border-double border-black">
                    <td class="">
                        <b>Summe</b>
                    </td>
                    <td />
                    <td />
                    <td class="text-right">
                        <EuroAmount :amount="actualSum" />
                        <br>
                        <EuroAmount
                            v-if="actualSum != expectedSum"
                            planned
                            :amount="expectedSum"
                        />
                    </td>
                    <td class="text-right">
                        /
                    </td>
                    <td class="text-right">
                        <EuroAmount :amount="targetSum" />
                        <br>
                    </td>
                    <td class="text-right">
                        <EuroAmount :amount="actualSum - targetSum" />
                        <br>
                        <EuroAmount
                            v-if="actualSum != expectedSum"
                            planned
                            :amount="expectedSum - targetSum"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import { useBackend } from '@/composables/useBackend'
import type { components } from '@/models/openapi'
import {
    computed, ref, watch, type Ref, 
} from 'vue'
import EuroAmount from '../EuroAmount.vue'

const { getFond } = useBackend()

const props = defineProps({
    fondId: {
        type: Number,
        default: 0,
    },
    year: {
        type: Number,
        default: new Date().getFullYear(),
    },
})

watch(props, async (newProps) => {
    fond.value = await getFond(newProps.year, newProps.fondId)
})

const fond: Ref<components['schemas']['Fond']> = ref(await getFond(props.year, props.fondId))
const nameFilter = ref('')
const filteredBudgets = computed(() => fond.value.budgets.filter(budget => budget.name.toLowerCase().includes(nameFilter.value.toLowerCase())))

const updateFond = async () => {
    fond.value = await getFond(props.year, props.fondId)
}

const actualSum = computed(
    () =>
        filteredBudgets.value.reduce((acc: number, item: components['schemas']['YearBudgetReceiver']) => {
            return acc + parseFloat(item.actual) * 100
        }, 0) / 100,
)

const expectedSum = computed(
    () =>
        filteredBudgets.value.reduce((acc: number, item: components['schemas']['YearBudgetReceiver']) => {
            return acc + parseFloat(item.expected) * 100
        }, 0) / 100,
)

const targetSum = computed(
    () =>
        filteredBudgets.value.reduce((acc: number, item: components['schemas']['YearBudgetReceiver']) => {
            return acc + parseFloat(item.target) * 100
        }, 0) / 100,
)
</script>
