<template>
    <div>
        <h1 class="text-4xl pb-10">
            Das hat leider nicht geklappt :(
        </h1>
        Bitte versuche die Anmeldung <a
            href="/accounts/login"
            class="text-tum-blue-dark-4"
        >hier</a> erneut.<br>
        Falls du deinen Account noch nicht für das Finanztool freigeschaltet hast, wende dich bitte an das Finanzreferat.
    </div>
</template>