import { createFetch } from '@vueuse/core'
import Cookies from 'universal-cookie'

import { useToast } from 'primevue/usetoast'


export function useUtils() {
    const toast = useToast()
    const cookies = new Cookies()

    function toBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve((reader.result as string).split(",")[1])
            reader.onerror = (error) => reject(error)
        })
    }

    const fetch = createFetch({
        fetchOptions: {
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': cookies.get('csrftoken'),
            },
        
        },
        options: {
            async onFetchError(ctx) {   
                toast.add({
                    severity: 'error',
                    summary: 'Es ist ein Fehler aufgetreten',
                    detail: JSON.parse(ctx.data)?.detail,
                    life: 3000,
                })
                return {
                    hasError: true,
                    data: null,
                    error: ctx.data,
                }
            }, 
        },
    })

    return {
        fetch,
        toBase64,
    }
}

export function useDate() {
    const padTo2Digits = (num: number) => {
        return num.toString().padStart(2, '0')
    }
    const format = (date: Date) =>
        [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('.')

    return { format }
}
