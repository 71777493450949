<template>
    <BaseCard>
        <template #title>
            Neu hinzugefügt
        </template>
        <template #default>
            <div cy-index-tabview-recent>
                <PTabView>
                    <PTabPanel>
                        <template #header>
                            <span class="mr-2 ">Rechnungen</span><Badge
                                v-if="recentInvoices?.length"
                                :value="recentInvoices?.length"
                                class="!bg-tum-orange-dark"
                            />
                        </template>
                        <div cy-index-recentinvoices>
                            <InvoiceRow
                                v-if="recentInvoices?.length"
                                class="max-h-[40rem]"
                                :items="recentInvoices"
                            />
                            <span v-else>
                                Du bist auf dem neusten Stand! Es gibt keine alten Anträge
                            </span>
                        </div>
                    </PTabPanel>
                    <PTabPanel>
                        <template #header>
                            <span class="mr-2">Anträge</span><Badge
                                v-if="recentRequests?.length"
                                :value="recentRequests?.length"
                                class="!bg-tum-blue-bright-dark"
                            />
                        </template>
                        <div cy-index-recentrequests>
                            <InvoiceRow
                                v-if="recentRequests?.length"
                                class="max-h-[40rem]"
                                :items="recentRequests"
                            />
                            <span v-else>
                                Du bist auf dem neusten Stand! Es gibt keine alten Anträge
                            </span>
                        </div>
                    </PTabPanel>
                    <PTabPanel>
                        <template #header>
                            <span class="mr-2">Sonstiges</span>
                        </template>
                        Du bist auf dem neusten Stand! Es wurden keine neuen Elemente im letzten Monat hinzugefügt
                    </PTabPanel>
                </PTabView>
            </div>
        </template>
    </BaseCard>
</template>

<script setup lang="ts">
import { useBackend } from '@/composables/useBackend'
import Badge from 'primevue/badge'


const {
    getRecentInvoices, getRecentRequests, 
} = useBackend()
const {
    data: recentInvoices, suspense: invoiceSuspense, 
} = getRecentInvoices()
const { data: recentRequests } = getRecentRequests()

await invoiceSuspense()
</script>