<template>
    <div>
        <h1 class="text-4xl pb-10">
            Administratoreinstellungen
        </h1>
        <span class="text-lg">Neue Remotenutzeraccounts hinzufügen:</span>
        <div class="flex gap-2 mt-2">
            <InputText
                v-model="ssoUser"
                class="w-96"
                placeholder="LRZ-Kürzel oder AStA-Nutzername"
                @keydown.enter.exact="authorizeUser"
            />
            <PButton
                :disabled="!ssoUser"
                @click="authorizeUser"
            >
                Nutzer <pre v-if="ssoUser"> (sso_{{ ssoUser }}) </pre> für das Finanztool Autorisieren
            </PButton>
        </div>
        Rechte können <a href="/admin/auth/user/">hier</a> angepasst werden.
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useBackend } from '@/composables/useBackend'
import { useToast } from 'primevue/usetoast'

const { createSSOUser } = useBackend()
const toast = useToast()

const authorizeUser = async () => {
    const response = await createSSOUser(ssoUser.value)

    if (response.data.value) {
        toast.add({
            severity: 'success',
            summary: 'Nutzer hinzugefügt',
            detail: `Der Nutzer ${ssoUser.value} wurde erfolgreich im Finanztool registriert.`,
            life: 3000,
        })
    }
}

const ssoUser = ref('')
</script>