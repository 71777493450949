<template>
    <div>
        <div class="grid grid-cols-2">
            <Suspense>
                <template #default>
                    <StaleItemsCard />
                </template>
                <template #fallback>
                    <BaseCard>
                        <template #title>
                            <div>
                                Alte Rechnungen und Anträge
                            </div>
                        </template>
                        <PTabView>
                            <PTabPanel :disabled="true">
                                <template #header>
                                    <span class="mr-2">Rechnungen</span>
                                </template>
                                <InvoiceRowSkeleton />
                            </PTabPanel>
                            <PTabPanel :disabled="true">
                                <template #header>
                                    <span class="mr-2">Anträge</span>
                                </template>
                            </PTabPanel>
                        </ptabview>
                    </BaseCard>
                </template>
            </Suspense>
            
            <Suspense>
                <template #default>
                    <RecentItemsCard />
                </template>
                <template #fallback>
                    <BaseCard>
                        <template #title>
                            <div>
                                Neu hinzugefügt
                            </div>
                        </template>
                        <PTabView>
                            <PTabPanel :disabled="true">
                                <template #header>
                                    <span class="mr-2">Rechnungen</span>
                                </template>
                                <InvoiceRowSkeleton />
                            </PTabPanel>
                            <PTabPanel :disabled="true">
                                <template #header>
                                    <span class="mr-2">Anträge</span>
                                </template>
                            </PTabPanel>
                            <PTabPanel :disabled="true">
                                <template #header>
                                    <span class="mr-2">Sonstiges</span>
                                </template>
                            </PTabPanel>
                        </ptabview>
                    </BaseCard>
                </template>
            </Suspense>
            
            
            <BaseCard
                v-if="user?.user.is_superuser"
                class="col-span-2 max-w-full"
            >
                <template #title>
                    Kürzlich geändert
                </template>
                <div class="overflow-x-scroll">
                    <Timeline
                        v-if="events && !eventsLoading"
                        layout="horizontal"
                        :value="events"
                    >
                        <template #marker="slotProps">
                            <a
                                :href="slotProps.item.link"
                                class="items-center justify-center text-white rounded-full z-1 shadow-1"
                                :style="{ backgroundColor: slotProps.item.color }"
                            >
                                <div
                                    v-tooltip.top="slotProps.item.class_name"
                                    class="w-6 h-6"
                                />
                            </a>
                        </template>
                        <template #content="slotProps">
                            <div class="truncate mr-5 max-w-xs">
                                <div>{{ slotProps.item.name || "Anonymous" }}</div>
                                <div class="text-sm">
                                    {{ slotProps.item.action }} <br>
                                    von {{ slotProps.item.user }}
                                </div>
                                <small class="p-text-secondary">{{ dateString(slotProps.item.action_time) }} days ago</small>
                            </div>
                        </template>
                    </Timeline>
                    <PSkeleton
                        v-else
                        class="!h-40 my-4"
                    />
                </div>
            </BaseCard>
            <template v-if="stats && !statsLoading">
                <BaseCard>
                    <template #default>
                        <div class="flex gap-10 my-5 justify-between">
                            <div class="flex gap-5">
                                <div class="flex flex-col">
                                    <span class="text-xl">Anbindung SAP</span>
                                    <span class="italic">letzter Import</span>
                                </div>
                                <div>
                                    <span class="text-5xl mr-3">{{ dateString(stats.sap || '') || '-' }}</span>
                                    <span class="text-tum-grey-4">days ago</span>
                                </div>
                            </div>
                            <div class="flex gap-5">
                                <div class="flex flex-col">
                                    <span class="text-xl">Status Antrag</span>
                                    <span class="italic">durchschnittliche Bearbeitungszeit</span>
                                </div>
                                <div>
                                    <span class="text-5xl mr-3">{{ stats.request_processing_time || "-" }}</span>
                                    <span class="text-tum-grey-4">days</span>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <PTabView>
                            <PTabPanel
                                v-for="fond in stats.budget_utilization"
                                :key="fond.name"
                            >
                                <template #header>
                                    {{ fond.name }}
                                </template>
                                <Chart
                                    class="my-5 w-full"
                                    type="bar"
                                    :data="setChart2('relative Budgetzielabweichung', fond.labels, fond.relative_values)"
                                    :options="chartOptions2"
                                />
                                <Chart
                                    class="my-5 w-full"
                                    type="bar"
                                    :data="setChart2('absolute Budgetzielabweichung', fond.labels, fond.absolute_values)"
                                    :options="chartOptions3"
                                />
                            </PTabPanel>
                        </PTabView>
                    </template>
                </BaseCard>
            
                <BaseCard>
                    <template #default>
                        <div class="flex justify-between my-5">
                            <div class="flex gap-5">
                                <div class="flex flex-col">
                                    <span class="text-xl">Anzahl der Rechnungen</span>
                                    <span class="italic">vergangener Monat</span>
                                </div>
                                <div>
                                    <span class="text-5xl mr-3">{{ stats.invoices_by_status.recent_values.reduce((a,b) => a+b, 0) || "-" }}</span>
                                    <span class="text-tum-grey-4">{{ stats.invoices_by_status.count_previous_year || "-" }} im Vorjahr</span>
                                </div>
                            </div>
                            <div class="flex gap-5">
                                <div class="flex flex-col">
                                    <span class="text-xl">Anzahl der Anträge</span>
                                    <span class="italic">vergangener Monat</span>
                                </div>
                                <div>
                                    <span class="text-5xl mr-3">{{ stats.requests_by_status.recent_values.reduce((a,b) => a+b, 0) || "-" }}</span>
                                    <span class="text-tum-grey-4">{{ stats.requests_by_status.count_previous_year || "-" }} im Vorjahr</span>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <div class="flex my-5">
                            <Chart
                                type="doughnut"
                                :data="setInvoiceChart(stats.invoices_by_status.labels, stats.invoices_by_status.recent_values, stats.invoices_by_status.stale_values)"
                                :options="chartOptions"
                                class="w-1/2 text-center"
                            />
                            <Chart
                                type="doughnut"
                                :data="setRequestChart(stats.requests_by_status.labels, stats.requests_by_status.recent_values, stats.requests_by_status.stale_values)"
                                :options="chartOptions"
                                class="w-1/2 text-center"
                            />
                        </div>
                        <div class="w-full text-center">
                            <small>Elemente der letzten 30 Tage befinden sich im Außenkreis, alle älteren, die noch offen sind befinden sich im Innenkreis</small>
                        </div>  
                    </template>
                </BaseCard>
            </template>
            <template v-else>
                <BaseCard
                    v-for="i in 2"
                    :key="i"
                >
                    <template #default>
                        <div class="flex gap-10 my-5 justify-between">
                            <PSkeleton class="!h-10" />
                        </div>
                        <hr>
                        <PSkeleton class="!h-40 mt-4" />
                    </template>
                </BaseCard>
            </template>
            
            <GlobalSearchCard />
        </div>
    </div>
</template>

<script setup lang="ts">
import Timeline from 'primevue/timeline';
import Chart from 'primevue/chart'
import { useBackend } from '@/composables/useBackend'
import {
    computed,
    ref, 
} from 'vue';
import GlobalSearchCard from '@/components/dashboard/GlobalSearchCard.vue';

const {
    getEvents, getStats, getLoggedInUser,
} = useBackend()

const {
    data: events, isLoading: eventsLoading, 
} = getEvents(computed(() => 300), computed(() => 0))

const {
    data: stats, isLoading: statsLoading, 
} = getStats()
const { data: user } = getLoggedInUser()

const chartOptions = ref({ plugins: { legend: { labels: { usePointStyle: true } } } })
const chartOptions2 = ref({
    scales: {
        y: {
            max: 1,
            min: -1,
        },
        x: {
            ticks: {
                autoSkip: false,
                maxRotation: 90,
                minRotation: 90,
            }, 
        },
    }, 
});
const chartOptions3 = ref({
    scales: {
        x: {
            ticks: {
                autoSkip: false,
                maxRotation: 90,
                minRotation: 90,
            }, 
        }, 
    }, 
});

const dateString = (date: string) => {
    return Math.ceil((new Date().getTime() - new Date(Date.parse(date)).getTime()) / (1000 * 3600 * 24)); 
}


const setInvoiceChart = (labels: string[] = [], recent_values: number[] = [], stale_values: number[] = []) => {
    return setChart1(labels, recent_values, stale_values, [
        '#D95117',
        '#EA7237',
        '#D99208',
        '#FED702',
        '#9FBA36',
        '#7D922A',
        '#000',
    ])
}

const setRequestChart = (labels: string[] = [], recent_values: number[] = [], stale_values: number[] = []) => {
    return setChart1(labels, recent_values, stale_values, [
        '#D95117',
        '#FED702',
        '#9FBA36',
        '#7D922A',
    ])
}

const setChart1 = (labels: string[] = [], recent_values: number[] = [], stale_values: number[] = [], colors: string[]) => {
    return {
        labels: labels,
        datasets: [
            {
                data: recent_values,
                backgroundColor: colors,
            }, {
                data: stale_values,
                backgroundColor: colors,
            },
        ],
    }}

const setChart2 = (chartLabel: string, labels: string[], values: string[]) => {
    return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        labels: labels,
        datasets: [
            {
                backgroundColor: values.map(v => parseFloat(v) < 0.0 ? '#D95117' : '#7D922A'),
                minBarLength: 5,
                label: chartLabel,
                data: values,
                yAxisID: 'y',
            },
        ],
    };
}
</script>
