<template>
    <Dialog
        :visible="isVisible"
        header="Neues Unterbudget erstellen"
        :modal="true"
        :dismissable-mask="true"
        class="w-5/6"
        cy-budgetmodal-dialog
        @update:visible="emit('update:is-visible')"
    >
        <small>
            Du bist mittendrin ein Unterbudget anzulegen. Bitte nutze diese Funktion mit Bedacht, löschen können nur
            Administratoren!
        </small>
        
        <br>Übergeordnetes Budget: <span cy-budgetmodal-span-parent>{{ parentName }}</span>

        <div class="flex flex-col">
            <label for="budget-name">Name</label>
            <InputText
                id="budget-name"
                v-model="budget.name"
                type="text"
                cy-budgetmodal-inputtext-name
            />
            <small
                v-if="errors.name"
                id="budget-name-help"
                cy-budgetmodal-inputtext-name-help
            >
                <span
                    v-for="error in errors.name"
                    :key="error"
                    class="text-tum-orange-danger"
                >
                    {{ error }} <br>
                </span>
            </small>
        </div>
        <br>

        <div class="flex flex-col">
            <label for="budget-target">Zielwert</label>
            <InputText
                id="budget-target"
                v-model="budget.target"
                type="number"
                cy-budgetmodal-inputtext-target
            />
            <small
                v-if="errors.target"
                id="budget-target-help"
            >
                <span
                    v-for="error in errors.target"
                    :key="error"
                    class="text-tum-orange-danger"
                >
                    {{ error }} <br>
                </span>
            </small>
        </div>

        <template #footer>
            <PButton
                label="Speichern"
                cy-budgetmodal-pbutton-submit
                @click="submitRequest"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import type { components } from '@/models/openapi'
import {
    ref, watch, type Ref, computed, toRef, 
} from 'vue'
import Dialog from 'primevue/dialog'
import { useToast } from 'primevue/usetoast'
import { useBackend } from '@/composables/useBackend'

const toast = useToast()
const {
    getBudgetDetails, upsertBudget, 
} = useBackend()

interface Props {
    isVisible?: boolean
    pk?: number
    parentPk?: number
    parentName?: string
    requesterPk: number
}

const props = withDefaults(defineProps<Props>(), {
    isVisible: false,
    pk: undefined,
    parentPk: undefined,
    parentName: 'root',
    isBudgetReceiver: false,
})

const emit = defineEmits([
    'update:is-visible',
    'save',
    'update',
])

const budgetPk = computed(() => props.pk)
const isCreateModal = computed(() => !props.pk)
const parentName = toRef(props, 'parentName')

const { data: budgetDetails } = getBudgetDetails(budgetPk, (data: components['schemas']['BudgetReply']) => {
    budget.value = {
        requester: data.requester?.pk || -1,
        description: data.description,
        name: data.name,
        parent: data.parent || props.parentPk,
        target: Math.abs(parseFloat(data.target)).toString(),
    }
})

const budget: Ref<components['schemas']['BudgetRequest'] | components['schemas']['PatchedBudgetRequest']> = ref({
    name: budgetDetails.value?.name || '',
    requester: budgetDetails.value?.requester?.pk || props.requesterPk || -1,
    target: budgetDetails.value?.target || '',
    parent: budgetDetails.value?.parent || props.parentPk,
})

watch(props, (newProps) => {
    budget.value.requester = newProps.requesterPk
})

const errors: Ref<Partial<components['schemas']['BudgetRequest']>> = ref({})

const submitRequest = async () => {
    if (!budget.value.requester) return

    const {
        error, data, 
    } = await upsertBudget(budget, props.pk)
    
    if (error.value) {
        errors.value = JSON.parse(error.value as string)
    } else if (typeof data.value === 'string') {
        emit(isCreateModal.value ? 'save' : 'update', JSON.parse(data.value as string))
        toast.add({
            severity: 'success',
            summary: 'Budget erstellt',
            detail: 'Das Budget wurde erfolgreich erstellt.',
            life: 3000,
        })
        emit('update:is-visible', false)
    }
}
</script>
