<template>
    <Dialog
        :visible="isVisible"
        :header="isCreateModal ? 'Anfrage erstellen' : 'Anfrage bearbeiten'"
        :modal="true"
        :dismissable-mask="true"
        class="w-5/6"
        cy-requestmodal-dialog
        @update:visible="emit('update:is-visible')"
    >
        <small>
            Du bist mittendrin eine Anfrage auf Dein Budget an das Referat für Finanzen zu stellen. Bitte nimm dir die
            Zeit und mache die Angaben <b>sorgfältig und gewissenhaft</b>. Das erspart uns und euch einiges an
            Mehraufwand. Wenn du eine Anfrage abgeschickt hast, hast du immernoch die Möglichkeit diese zu bearbeiten
            und anzupassen bis wir diese weiter bearbeiten. Du erhältst in den nächsten Tagen eine Antwort mit weiteren
            Schritten, die für eine Genehmigung notwendig sind.<br>
            Bitte beachte die Hinweise, die an den einzelnen Feldern stehen, diese erklären was gemeint ist oder liefern
            ein Beispiel.<br>
        </small>

        <small id="general-help">
            <span
                v-for="error in errors.__all__"
                :key="error"
                class="text-tum-orange-danger"
                cy-general-help
            >
                {{ error }} <br>
            </span>
        </small>

        <div class="items-top flex w-full gap-5">
            <div class="flex w-1/2 flex-col">
                <label for="budget-requester">Auftraggeber*in</label>
                <PDropdown
                    v-model="budget.requester"
                    filter
                    option-label="name"
                    option-value="pk"
                    :options="requesters"
                    cy-requestmodal-dropdown-requester
                />
                <small id="budget-requester-help">
                    <span
                        v-for="error in errors.requester"
                        :key="error"
                        class="text-tum-orange-danger"
                        cy-requestmodal-dropdown-requester-help
                    >
                        {{ error }} <br>
                    </span>
                </small>
            </div>

            <div
                v-if="budget.requester"
                class="flex w-1/2 flex-col"
            >
                <label for="budget-parent">Übergeordnetes Budget</label>
                <PDropdown
                    v-model="budget.parent"
                    filter
                    option-label="name"
                    option-value="pk"
                    :options="potentialParents"
                    cy-requestmodal-dropdown-budget
                />
                <small id="budget-parent-help">
                    <span
                        v-for="error in errors.parent"
                        :key="error"
                        class="text-tum-orange-danger"
                    >
                        {{ error }} <br>
                    </span>
                </small>
            </div>
        </div>
        <br>

        <template v-if="budget.request && budget.requester">
            <div class="flex flex-col">
                <label for="budget-name">Name</label>
                <InputText
                    id="budget-name"
                    v-model="budget.name"
                    type="text"
                    :class="{ 'p-invalid': errors.name }"
                    cy-requestmodal-inputtext-name
                />
                <small id="budget-name-help">
                    <span
                        v-for="error in errors.name"
                        :key="error"
                        class="text-tum-orange-danger"
                    >
                        {{ error }} <br>
                    </span>
                    Gib hier den Namen der Anfrage an (Beispiel: Büroartikel, Serverteile, ...)
                </small>
            </div>
            <br>
            <div class="flex flex-col">
                <label for="budget-name">Begründung</label>
                <PTextarea
                    id="budget-name"
                    v-model="budget.request.reason"
                    :auto-resize="true"
                    :class="{ 'p-invalid': errors.request?.reason }"
                    cy-requestmodal-ptextarea-reason
                />
                <small id="budget-name-help">
                    <span
                        v-for="error in errors.request?.reason"
                        :key="error"
                        class="text-tum-orange-danger"
                    >
                        {{ error }} <br>
                    </span>
                    Gib hier eine Begründung an, <br>
                    1. Warum sich die Anschaffung gemäß dem BayHSchG (Art. 52 Abs. 2 Satz 3) rechtfertigen lässt<br>
                    2. Warum ihr das Angebot#1 präferiert, falls mehrere Angebote eingeholt wurden<br>
                    Diese Begründung wird an die Finanzbuchhaltung weitergeleitet und entscheidet, ob dein Antrag
                    angenommen wird. Er sollte bei Beträgen kleiner 500€ einige wenige Sätze umfassen und bei teureren
                    Anschaffungen in einen längeren Fließtext resultieren.<br>
                    Hinweis: Das Feld wird sich automatisch erweitern, sobald du mehrere Zeilen schreibst.
                </small>
            </div>
            <br>

            <div class="flex flex-col">
                <FileUpload
                    id="receipt"
                    accept="application/pdf"
                    cy-requestmodal-fileupload-receipt
                    :value="budget.request.receipt?.name"
                    @input="onReceiptUpload"
                />
                <small>
                    <span
                        v-for="error in errors.request?.receipt"
                        :key="error"
                        class="text-tum-orange-danger"
                    >
                        {{ error }} <br>
                    </span>
                    Bitte lade hier <b>ein</b> Angebot von dem Wunschhändler bei dem ihr bestellen wollt hoch. Es genügt
                    hier ein Screenshot von dem Warenkorb einer Webseite oder ähnliches. Es werden hier keine
                    Vergleichsangebote gefordert!
                </small>
            </div>
            <br>

            <div class="items-top flex w-full gap-5">
                <div class="flex flex-col">
                    <label for="budget-target">Auftragspreis</label>
                    <InputText
                        id="budget-target"
                        :value="parseFloat(budget.target || '')"
                        type="number"
                        :class="{ 'p-invalid': errors.target }"
                        cy-requestmodal-inputtext-target
                        @input="(event: Event) => (budget.target = (event.target as HTMLInputElement).value)"
                    />
                    <small>
                        <span
                            v-for="error in errors.target"
                            :key="error"
                            class="text-tum-orange-danger"
                        >
                            {{ error }} <br>
                        </span>
                    </small>
                    <small v-if="budgetDetails?.requester?.booking_type === 1">
                        Gib hier den Auftragspreis in netto an. Damit ist der Preis der gesamten Anfrage
                        <b>ohne Steuern</b> gemeint. Dies wird in Rechnungen als "Netto-Preis" bezeichnet.
                    </small>
                    <small v-else>
                        Gib hier den Auftragspreis in brutto an. Damit ist der Preis der gesamten Anfrage
                        <b>inklusive Steuern</b> gemeint. Dies wird in Rechnungen als "Brutto-Preis" bezeichnet.
                    </small>
                </div>
                <br>

                <div class="flex flex-col">
                    <label for="request-netsingleamount">Größter Einzelpreis</label>
                    <InputText
                        id="request-netsingleamount"
                        v-model="budget.request.net_single_amount"
                        type="number"
                        :class="{
                            'p-invalid': errors.request?.net_single_amount,
                        }"
                        cy-requestmodal-inputtext-netsingleamount
                    />
                    <small>
                        <span
                            v-for="error in errors.request?.net_single_amount"
                            :key="error"
                            class="text-tum-orange-danger"
                        >
                            {{ error }} <br>
                        </span>
                        <br>
                        Bitte gib hier den größten Netto-Einzelpreis ein. Damit ist der größte Preis
                        <b>eines einzelnen</b> Artikels <b>ohne Steuern</b> gemeint.<br>
                        (Beispiel: Man kaufe 10 Tacker à 20€ (ohne Steuern) und 2 Drucker à 150€ (ohne Steuern). Der
                        einzutragende Preis beträgt 150€, da der Einzelpreis eines Druckers hier am größten ist)
                    </small>
                </div>
            </div>

            <br>

            <div v-if="requiresOffers">
                <small>
                    Der folgende Abschnitt ist nur relevant, falls der Netto-Einzelpreis größer gleich 250€ ist. In
                    diesem Fall brauchen wir insgesamt drei eingeholte Vergleichsangebote. Sollten mehrere Einzelpreise
                    über 250€ sein, legt bitte für jeden Posten über 250€ eine Anfrage an.<br>
                    Dafür habt ihr zwei Möglichkeiten:<br>
                    1. Website mit Preis als pdf drucken (Strg+P auf der Website und dann "Als PDF drucken" auswählen,
                    bitte alle nicht relevanten Seiten wegschneiden)<br>
                    2. Angebot beim Hersteller einholen<br>
                    Tragt in Angebot Eins euer bevorzugtes Angebot ein.<br>
                    Beim Feld Verkäufer*in bitte den Namen der Firma inklusive der Rechtsrequest an. Diesen findet ihr
                    auf dem Angebot bzw. im Impressum auf der Website (Beispiel: office dismount <b>GmbH</b>, Azamon EU
                    <b>S.à r.l.</b>)<br>
                    Mit Netto-<b>Auftragspreis</b> ist der Preis der gesamten Anfrage <b>ohne Steuern</b> gemeint. Dies
                    wird in Rechnungen als "Netto-Gesamtpreis" bezeichnet.<br>
                </small>
                <table class="border-collapse">
                    <thead>
                        <th />
                        <th>Firma</th>
                        <th>Netto-Gesamtbetrag</th>
                        <th>Angebotsdatei</th>
                    </thead>
                    <!-- for offers -->
                    <template v-if="budget.request.offers">
                        <tr
                            v-for="index in 3"
                            :key="index"
                        >
                            <template v-if="budget.request.offers.length >= index">
                                <td class="pt-8">
                                    {{ index }}
                                </td>
                                <td>
                                    <div class="flex flex-col">
                                        <label for="request-offers-{{ index-1 }}-bidder">Verkäufer*in</label>
                                        <InputText
                                            id="request-offers-{{ index-1 }}-bidder"
                                            v-model="budget.request.offers[index - 1].bidder"
                                            type="text"
                                            :class="{
                                                'p-invalid': errors.request?.offers && errors.request.offers[index]?.bidder,
                                            }"
                                        />
                                        <small
                                            v-if="errors.request?.offers"
                                            id="budget-name-help"
                                        >
                                            <span
                                                v-for="error in errors.request.offers[index-1]?.bidder"
                                                :key="error"
                                                class="text-tum-orange-danger"
                                            >
                                                {{ error }} <br>
                                            </span>
                                        </small>
                                    </div>
                                </td>
                                <td>
                                    <div class="flex flex-col">
                                        <label for="request-offers-{{ index-1 }}-net">Netto-Auftragspreis</label>
                                        <InputText
                                            id="request-offers-{{ index-1 }}-net"
                                            v-model="budget.request.offers[index - 1].net"
                                            type="number"
                                            :class="{
                                                'p-invalid': errors.request?.offers && errors.request.offers[index-1]?.net,
                                            }"
                                        />
                                        <small
                                            v-if="errors.request?.offers"
                                            id="budget-name-help"
                                        >
                                            <span
                                                v-for="error in errors.request.offers[index-1]?.net"
                                                :key="error"
                                                class="text-tum-orange-danger"
                                            >
                                                {{ error }} <br>
                                            </span>
                                        </small>
                                    </div>
                                </td>
                                <td>
                                    <div class="flex flex-col">
                                        <FileUpload
                                            id="request-offers-{{ index-1}}-file"
                                            :class="{
                                                'p-invalid':
                                                    errors.request?.offers && errors.request.offers[index-1]?.offer_file,
                                            }"
                                            :value="budget.request.offers[index-1].offer_file.name"
                                            @input="(file: File) => onOfferUpload(index - 1, file)"
                                        />
                                        <small
                                            v-if="errors.request?.offers"
                                            id="budget-name-help"
                                        >
                                            <span
                                                v-for="error in errors.request.offers[index-1]?.offer_file?.data"
                                                :key="error"
                                                class="text-tum-orange-danger"
                                            >
                                                {{ error }} <br>
                                            </span>
                                        </small>
                                    </div>  
                                </td>
                            </template>
                        </tr>
                    </template>
                </table>
            </div>
        </template>

        <template #footer>
            <PButton
                label="Speichern"
                cy-requestmodal-button-submit
                @click="submitRequest"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog'
import {
    toRef, type Ref, computed, ref, 
} from 'vue'
import { useToast } from 'primevue/usetoast'
import type { components } from '@/models/openapi'
import { useUtils } from '@/composables/useUtils'
import { useBackend } from '@/composables/useBackend'


const toast = useToast()

const {
    getRequesters, getPotentialParents, getBudgetDetails, upsertBudget,
} = useBackend()
const utils = useUtils()

const props = defineProps({
    pk: {
        type: Number,
        default: undefined,
    },
    parentPk: {
        type: Number,
        default: undefined,
    },
    requesterPk: {
        type: Number,
        default: undefined,
    },
    isVisible: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits([
    'update:is-visible',
    'save',
    'update',
])

const budgetPk = toRef(props, 'pk')

const { data: requesters } = getRequesters()
const { data: budgetDetails } = getBudgetDetails(budgetPk, (data: components['schemas']['BudgetReply']) => {
    budget.value = {
        requester: data.requester?.pk || props.requesterPk || -1,
        description: data.description,
        name: data.name,
        parent: data.parent || props.parentPk,
        target: Math.abs(parseFloat(data.target)).toString(),
    }
    if (data.request || !budgetPk.value) {
        budget.value.request = {
            net_single_amount: data.request?.net_single_amount,
            reason: data.request?.reason,
        }
        if (data.request?.receipt) {
            budget.value.request.receipt = {
                name: data.request.receipt,
                data: '',
            }
        }
        budget.value.request.offers = []
        data.request?.offers.forEach((offer) => 
            budget.value.request?.offers?.push({
                bidder: offer.bidder,
                is_desired: offer.is_desired,
                net: offer.net,
                offer_file: {
                    name: offer.offer_file,
                    data: '', 
                },
            }))
        if (budget.value.request.offers.length === 0) {
            for (let i = 0; i < 3; i++) {
                budget.value.request.offers.push({
                    bidder: '',
                    is_desired: i === 0,
                    net: '',
                    offer_file: {
                        name: '',
                        data: '', 
                    },
                })
            }
        }
    }
})

const budget: Ref<components['schemas']['BudgetRequest'] | components['schemas']['PatchedBudgetRequest']> = ref({
    name: budgetDetails.value?.name || '',
    requester: budgetDetails.value?.requester?.pk || -1,
    target: budgetDetails.value?.target || '',
})

const { data: potentialParents } = getPotentialParents(computed(() => {
    return budget.value.requester || -1
}), computed(() => !!budgetDetails.value?.pk || !props.pk))

const onReceiptUpload = async (file: File) => {
    if (budget.value.request) {
        budget.value.request.receipt = {
            name: file.name,
            data: await utils.toBase64(file),
        }
    }
}
const onOfferUpload = async (index: number, file: File) => {
    if (budget.value.request?.offers && budget.value.request.offers[index]) {
        budget.value.request.offers[index].offer_file = {
            name: file.name,
            data: await utils.toBase64(file),
        }
    }
}

const requiresOffers = computed(() => budget.value.request?.net_single_amount && parseFloat(budget.value.request.net_single_amount) >= 250)
const isCreateModal = computed(() => !props.pk)

const errors: Ref<Partial<components['schemas']['BudgetRequest'] & { __all__: Record<string, string>}>> = ref({})

const submitRequest = async () => {
    const budgetCopy = budget.value
    
    // Sanity checks for attributes of budget and small adjustments
    if (!potentialParents.value?.some(item => item.pk === budgetCopy.parent)) budgetCopy.parent = null
    budgetCopy.target = (-1.0 * parseFloat(budget.value.target || '')).toString()
    if (budgetCopy.request) {
        if (budgetCopy.request.receipt) {
            if (budgetCopy.request?.receipt.data === '') {
                delete budgetCopy.request.receipt
            }
        }
    }
    if (!requiresOffers.value) {
        delete budgetCopy.request?.offers
    } else if (budgetCopy.request?.offers) {
        budgetCopy.request.offers = budgetCopy.request?.offers.filter((budget) => budget.offer_file.data !== '')
        if (budgetCopy.request.offers.length === 0) delete budgetCopy.request.offers
    }

    const {
        data, error, 
    } = await upsertBudget(ref(budgetCopy), props.pk)

    if (error.value && typeof error.value === 'string') {
        errors.value = JSON.parse(error.value as string)
    } else if (typeof data.value === 'string') {
        emit(isCreateModal.value ? 'save' : 'update', JSON.parse(data.value as string))
        toast.add({
            severity: 'success',
            summary: 'Antrag geändert',
            detail: 'Der Antrag wurde erfolgreich geändert.',
            life: 3000,
        })
        emit('update:is-visible', false)
    }
}
</script>
