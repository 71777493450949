<template>
    <ConfirmDialog />
    <Toast />
    <div class="bg-tum-blue-light-5 min-h-screen">
        <nav class="flex h-16 w-full items-center bg-tum-blue-dark px-4 py-1 text-3xl text-white">
            <span class="xl:block hidden">Studentische Vertretung - Finanzen</span>
            <span class="flex-1" />
            <template v-if="loggedInUser?.user">
                <div
                    v-for="link in links"
                    :key="link.link"
                    class="flex min-h-full items-center border-b-4 text-lg truncate"
                    :class="[route.path === link.link ? 'border-white' : 'border-transparent hover:border-white']"
                >
                    <component
                        :is="link.admin ? 'a' : RouterLink"
                        class="p-2 truncate"
                        :to="link.link"
                        :href="link.link"
                    >
                        {{ link.title }}
                    </component>
                </div>
            </template>
            <div
                v-if="loggedInUser?.user"
                class="flex min-h-full items-center border-b-4 border-transparent text-lg hover:border-white"
            >
                <a
                    class="p-2"
                    href="/oidc/logout"
                >
                    Logout
                </a>
            </div>
            <div
                v-else
                class="flex min-h-full items-center border-b-4 border-transparent text-lg hover:border-white"
            >
                <a
                    class="p-2"
                    href="/accounts/login"
                >
                    Login
                </a>
            </div>
        </nav>
        <div class="px-12 py-5 ">
            <RouterView />
        </div>
    </div>
</template>

<script setup lang="ts">
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import { useBackend } from './composables/useBackend'
import {
    useRoute, RouterLink, 
} from 'vue-router'

const { getLoggedInUser } = useBackend()
const { data: loggedInUser } = getLoggedInUser()
const route = useRoute()

const currentYear = new Date().getFullYear()

const links = [
    {
        link: '/new',
        title: 'Dashboard', 
    },
    {
        link: `/new/year/${currentYear}/0`,
        title: 'Jahresübersicht', 
    },
    {
        link: '/new/request',
        title: 'Anfrage erstellen', 
    },
    {
        link: '/admin',
        title: 'Adminoberfläche',
        admin: true,
    },
    {
        link: '/sap/upload',
        title: 'SAP Import', 
        admin: true,
    },
]

</script>
