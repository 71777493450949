<template>
    <div
        class="overflow-y-scroll"
    >
        <div
            v-for="requester in items"
            :key="requester.pk"
            class="flex flex-col gap-2 items-center"
            :cy-invoicerow-requester="requester.pk" 
        >
            <span
                class="w-full text-left font-semibold text-tum-blue-dark-1"
                :cy-invoicerow-requester-name="requester.pk"
            >
                {{ requester.name }}
            </span>
            <div class="bg-tum-blue-dark-1 h-0.5 w-full" />
            <div
                v-for="item in requester.items"
                :key="item.link"
                class="bg-tum-grey-9 w-full p-4 flex rounded-lg"
                :cy-invoicerow-item="item.link"
            >
                <div class="flex gap-4 items-center truncate mr-2">
                    <a :href="item.link">
                        <PButton
                            v-if="getUniqueId(item)"    
                            :label="getUniqueId(item)"
                        />
                        <PButton
                            v-else
                            icon="pi"
                            rounded
                        >   
                            <template #icon>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                    />
                                </svg>
                            </template>
                        </PButton>
                    </a>
                    <div class="truncate">
                        {{ item.display_name }}
                    </div>
                </div>
                <div class="flex-grow" />
                <div class="flex gap-2 items-center">
                    <div>
                        <Tag
                            severity="info"
                            class="whitespace-nowrap"
                            :cy-invoicerow-item-status="item.link"
                        >
                            {{ item.status.text }}
                        </Tag>
                    </div><div>
                        <Tag
                            v-if="item.date"
                            :severity="severity(item)"
                            class="whitespace-nowrap"
                            :cy-invoicerow-item-due="item.link"
                        >
                            {{ dateString(item.date) }} days ago
                        </Tag>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { components } from '@/models/openapi'
import Tag from 'primevue/tag'

interface Props {
    items: components['schemas']['RecentInvoiceGrouped'][] | components['schemas']['RecentRequestGrouped'][]
    severity?: () => string
}

const dateString = (date: string) => {
    return Math.ceil((new Date().getTime() - new Date(Date.parse(date)).getTime()) / (1000 * 3600 * 24)); 
}

const getUniqueId = (item: components['schemas']['Booking'] | components['schemas']['RecentRequest']) => {
    return "unique_id" in item ? item.unique_id : ""
}

withDefaults(defineProps<Props>(), {
    items: () => [],
    severity: () => 'info',
})
</script>