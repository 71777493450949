<template>
    <BaseCard>
        <template #title>
            <div class="flex items-center gap-5">
                <div>
                    Alte Rechnungen und Anträge
                </div>
                <PInlineMessage
                    v-if="hasOverdueItem"
                    severity="error"
                >
                    Rechnungen Überfällig!
                </PInlineMessage>
                <PInlineMessage
                    v-else-if="staleInvoices?.length || staleRequests?.length"
                    severity="warn"
                    cy-index-staleinvoices-badge
                >
                    Erfordert Aktion!
                </PInlineMessage>
            </div>
        </template>
        <template #default>
            <div cy-index-tabview-stale>
                <PTabView
                    lazy
                >
                    <PTabPanel>
                        <template #header>
                            <span class="mr-2">Rechnungen</span>
                            <Badge
                                v-if="staleInvoices?.length"
                                :value="staleInvoices?.length"
                                class="!bg-tum-orange-dark"
                            />
                        </template>
                        <div cy-index-staleinvoices>
                            <InvoiceRow
                                v-if="staleInvoices?.length"
                                class="max-h-[40rem]"
                                :items="staleInvoices"
                                :severity="(item: any) => isOverdue(item.date) ? 'danger' : 'warning'"
                            />
                            <span v-else>
                                Du bist auf dem neusten Stand! Es gibt keine alten Anträge
                            </span>
                        </div>
                    </PTabPanel>
                    <PTabPanel>
                        <template #header>
                            <span class="mr-2">Anträge</span>
                            <Badge
                                v-if="staleRequests?.length"
                                :value="staleRequests?.length"
                                class="!bg-tum-blue-bright-dark"
                            />
                        </template>
                        <div cy-index-stalerequests>
                            <InvoiceRow
                                v-if="staleRequests?.length"
                                class="max-h-[40rem]"
                                :items="staleRequests"
                            />
                            <span v-else>
                                Du bist auf dem neusten Stand! Es gibt keine alten Anträge
                            </span>
                        </div>
                    </PTabPanel>
                </PTabView>
            </div>
        </template>
    </BaseCard>
</template>

<script setup lang="ts">
import { useBackend } from '@/composables/useBackend';
import type { components } from '@/models/openapi';
import { computed } from 'vue';
import Badge from 'primevue/badge'

const {
    getStaleInvoices, getStaleRequests, 
} = useBackend()

const {
    data: staleInvoices, suspense: invoiceSuspense, 
} = getStaleInvoices()
const { data: staleRequests } = getStaleRequests()

await invoiceSuspense()

const hasOverdueItem = computed(() => {
    return staleInvoices.value?.reduce((a,b) => {
        const x = a
        a.items.concat(b.items)
        return x
    }, { items: [] as components['schemas']['Booking'][] }).items.find((item) => isOverdue(item.date))
})

const dateString = (date: string) => {
    return Math.ceil((new Date().getTime() - new Date(Date.parse(date)).getTime()) / (1000 * 3600 * 24)); 
}

const isOverdue = (date: string) => {
    return dateString(date) >= 60
}
</script>