import { useToast } from 'primevue/usetoast'

export const useCustomToast = () => {
    const toast = useToast()
    const TOAST_LIFE_TIME = 5000

    return {
        showErrorToast(detail = 'Bitte versuche es später erneut.') {
            toast.add({
                severity: 'error',
                summary: 'Es ist ein Fehler aufgetreten.',
                detail,
                life: TOAST_LIFE_TIME,
            })
        }, 
    }
}