<template>
    <BaseCard>
        <div class="m-10 w-11/12">
            Liebe*r {{ loggedInUser?.user.first_name || "Nutzer" }}, <br>
            in dieser Ansicht siehst du alle aktuellen Anfragen deiner Referate und deren Status. Mit der Aktion
            "Bearbeiten" kannst du die Anfrage noch bearbeiten - sofern wir diese noch nicht genehmigt haben.
            <table class="mx-auto my-5">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th class="text-right">
                            Budget
                        </th>
                        <th class="text-right">
                            Betrag
                        </th>
                        <th>
                            <PButton
                                rounded
                                text
                                cy-request-button-create-request
                                @click="openRequestDialog(undefined)"
                            >
                                <template #icon>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-5 w-5"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M12 4.5v15m7.5-7.5h-15"
                                        />
                                    </svg>
                                </template>
                            </PButton>
                        </th>
                    </tr>
                </thead>
                <tr
                    v-for="budget in budgets"
                    :key="budget.pk"
                    class="border-t-2 border-black"
                    :cy-request-tr="budget.pk"
                >
                    <td :cy-request-td-name="budget.pk">
                        {{ budget.name }}
                    </td>
                    <td
                        class="text-right"
                        :cy-request-td-requestername="budget.pk"
                    >
                        {{ budget.requester?.name }}
                    </td>
                    <td
                        class="text-right"
                        :cy-request-td-target="budget.pk"
                    >
                        <EuroAmount :amount="parseFloat(budget.target)" />
                    </td>
                    <td class="flex">
                        <PButton
                            rounded
                            text
                            :cy-request-button-edit="budget.pk"
                            @click="openRequestDialog(budget.pk)"
                        >
                            <template #icon>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="h-5 w-5"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                    />
                                </svg>
                            </template>
                        </PButton>
                    </td>
                </tr>
            </table>
            <div>
                <Suspense>
                    <template #default>
                        <RequestModal
                            v-if="isRequestDialogVisible"
                            v-model:is-visible="isRequestDialogVisible"
                            :pk="editPk"
                            @save="budgets.push($event)"
                            @update="budgets[budgets.findIndex(i => i.pk === $event.pk)] = $event"
                        />
                    </template>
                </Suspense>
            </div>
        </div>
    </BaseCard>
</template>

<script setup lang="ts">
import { useBackend } from '@/composables/useBackend'
import type { components } from '@/models/openapi'
import { useAsyncState } from '@vueuse/core'
import { ref } from 'vue'

const {
    getBudgets, getLoggedInUser, 
} = useBackend()

const isRequestDialogVisible = ref(false)
const editPk = ref()

const openRequestDialog = (pk?: number) => {
    editPk.value = pk
    isRequestDialogVisible.value = true
}

const { state: budgets } = useAsyncState<components['schemas']['BudgetList'][]>(getBudgets(true), [])
const { data: loggedInUser } = getLoggedInUser()
</script>
