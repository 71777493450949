<template>
    Upload file
    <label
        :for="id"
        class="border-gray-300 hover:border-blue-400 relative block h-[42px] overflow-hidden rounded border bg-white"
    >
        <input
            :id="id"
            type="file"
            :accept="accept"
            class="absolute top-0 left-0 right-0 bottom-0 block h-full w-full"
            v-bind="$attrs"
            @change="handleUpload"
        >
        <i class="pi pi-upload absolute left-2 top-3 z-10" />
        <span
            class="pointer-events-none absolute top-0 left-0 right-0 bottom-0 flex w-full items-center justify-center rounded-sm bg-white"
        >
            <div class="ml-8 mr-1 text-center max-w-full overflow-hidden">
                <small
                    name="file"
                    :file="file"
                    :upload-info="uploadInfo"
                    class="block max-w-full overflow-hidden whitespace-nowrap text-ellipsis"
                >
                    {{ uploadInfo }}
                </small>
            </div>
        </span>
    </label>
</template>

<script setup lang="ts">
import {
    ref, computed, type Ref, 
} from 'vue'
const emit = defineEmits(['input'])
const file: Ref<File | undefined> = ref()

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    accept: {
        type: String,
        default: '*',
    },
    value: {
        type: String,
        default: '',
    },
})

// display the uploaded file names (think computed)
const uploadInfo = computed(() => {
    return props.value
})

// handle the file upload event (think methods)
const handleUpload = (event: Event) => {
    if (event.target == null) return
    const files = (event.target as HTMLInputElement).files
    if (files) file.value = files[0]
    emit('input', file.value)
}
</script>
