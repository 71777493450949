import { createApp } from 'vue'

import App from './App.vue'
import primevue from '../plugins/primevue';
import router from '../plugins/router'
import components from '../plugins/auto-components'
import query from '../plugins/query';
import Column from 'primevue/column'
import Tooltip from 'primevue/tooltip'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import Dropdown from 'primevue/dropdown'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import InlineMessage from 'primevue/inlinemessage'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel' 
import Skeleton from 'primevue/skeleton'

import './assets/main.css'
import './assets/primevue-theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

const app = createApp(App)

app.use(primevue)
app.use(ConfirmationService)
app.use(ToastService)
app.use(router)
app.use(query)
app.use(components)

app.component('PColumn', Column)
app.component('PButton', Button)
app.component('InputText', InputText)
app.component('PDropdown', Dropdown)
app.component('PTextarea', Textarea)
app.component('PInlineMessage', InlineMessage)
app.component('PTabView', TabView)
app.component('PTabPanel', TabPanel)
app.component('PSkeleton', Skeleton)
app.directive('tooltip', Tooltip)

app.mount('#app')
