import type { components } from '@/models/openapi'
import { useQuery } from '@tanstack/vue-query'
import {
    ref, type Ref, 
} from 'vue'
import { useUtils } from '@/composables/useUtils'
import type {
    MaybeRef, UseFetchOptions, UseFetchReturn, 
} from '@vueuse/core'
import { useCustomToast } from '@/composables/useToast'

export function useBackend() {
    const { fetch: preconfiguredFetch } = useUtils()
    const toast = useCustomToast()

    const handleErrorsAndFetch = async <T>(url: MaybeRef<string>, options: RequestInit, useFetchOptions?: UseFetchOptions): Promise<UseFetchReturn<T> | { details: string }> => {
        const response = await preconfiguredFetch<T | string>(url, options, useFetchOptions)
        if (!response.error.value) return response as UseFetchReturn<T>
        if (typeof response.data.value === 'string') {
            toast.showErrorToast(JSON.parse(response.data.value))
            return JSON.parse(response.data.value)
        }
        return { details: 'Unbekannter Fehler.' }
    }

    const getLoggedInUser = () => {
        return useQuery({
            queryKey: ['whoami'],
            queryFn: (): Promise<components['schemas']['WhoAmIResponse']> => fetch(`/api/whoami`).then((d) => d.json()),
        })
    }

    const getBookings = (search: Ref<string>) => {
        return useQuery({
            queryKey: [
                'bookings', search.value,
            ],
            queryFn: (): Promise<components['schemas']['Booking'][]> => fetch(`/api/bookings/?search=${search.value}`).then((d) => d.json()),
        })
    }

    const getEvents = (from: Ref<number>, to: Ref<number>) => {
        return useQuery({
            queryKey: [
                'events',
                from,
                to,
            ],
            queryFn: (): Promise<components['schemas']['Log'][]> => fetch(`/api/misc/logs/?from=${from.value}&to=${to.value}`).then((d) => d.json()),
        })
    }

    const getStats = () => {
        return useQuery({
            queryKey: ['stats'],
            queryFn: (): Promise<components['schemas']['Stats']> => fetch(`/api/misc/stats`).then((d) => d.json()),
        })
    }

    const getRecentInvoices = () => {
        return useQuery({
            queryKey: ['recentbookings'],
            queryFn: (): Promise<components['schemas']['RecentInvoiceGrouped'][]> => fetch(`/api/bookings/recent`).then((d) => d.json()),
        })
    }

    const getStaleInvoices = () => {
        return useQuery({
            queryKey: ['stalebookings'],
            queryFn: (): Promise<components['schemas']['RecentInvoiceGrouped'][]> => fetch(`/api/bookings/stale`).then((d) => d.json()),
        })
    }

    const getStaleRequests = () => {
        return useQuery({
            queryKey: ['stalerequests'],
            queryFn: (): Promise<components['schemas']['RecentInvoiceGrouped'][]> => fetch(`/api/requests/stale`).then((d) => d.json()),
        })
    }

    const getRecentRequests = () => {
        return useQuery({
            queryKey: ['recentrequests'],
            queryFn: (): Promise<components['schemas']['RecentRequestGrouped'][]> => fetch(`/api/requests/recent`).then((d) => d.json()),
        })
    }

    const getYearBudgetDetails = (pk: Ref<number | undefined>, isBudgetReceiver: Ref<boolean>, enabled: Ref<boolean> = ref(true)) => {
        return useQuery({
            queryKey: [
                'yearBudgetDetails',
                pk,
                isBudgetReceiver,
            ],
            queryFn: (): Promise<components['schemas']['YearBudgetDetail']> => fetch(`/api/year/${isBudgetReceiver.value ? 'budgetreceivers' : 'budgets'}/${pk.value}`).then((d) => d.json()),
            enabled: enabled,
        })
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getBudgetDetails = (pk: Ref<number | undefined>, onSuccess?: any) => {
        return useQuery({
            queryKey: [
                'budgetDetails', pk,
            ],
            queryFn: (): Promise<components['schemas']['BudgetReply']> => pk.value ? fetch(`/api/budgets/${pk.value}`).then((d) => d.json()) : Promise.resolve({
                name: '',
                target: '',
            }),
            onSuccess,
            refetchOnWindowFocus: false,
        })
    }

    const getFond = (year: number, pk: number): Promise<components['schemas']['Fond']> => {
        return fetch(`/api/year/${year}/fond/${pk}`).then((d) => d.json())
    }

    const getPotentialParents = (requesterPk: Ref<number | undefined>, enabled=ref(true)) => {
        return useQuery({
            queryKey: [
                'potentialParents', requesterPk,
            ],
            queryFn: (): Promise<components['schemas']['BudgetParentList'][]> => requesterPk.value && requesterPk.value >= 0 ? fetch(`/api/budgets/potential_parents?requester=${requesterPk.value}`).then((d) => d.json()) : Promise.resolve([]),
            enabled,
        })
    }

    const getAnnualPlan = (year: number) => {
        return useQuery({
            queryKey: [
                'annualPlan', year,
            ],
            queryFn: async (): Promise<components['schemas']['Year']> => {
                const response = await fetch(`/api/year/${year}`)
                return await response.json()
            },
        })
    }

    const getRequesters = () => {
        return useQuery({
            queryKey: ['requesters'],
            queryFn: (): Promise<components['schemas']['Requester'][]> => fetch(`/api/requesters/`).then((d) => d.json()),
        })
    }

    const getBudgets = (requestsOnly: boolean) => {
        return fetch(`/api/budgets?requestsOnly=${requestsOnly}`).then((d) => d.json())
    }

    const createSSOUser = (ssoUsername: string) => {
        return handleErrorsAndFetch<components['schemas']['User']>(`/api/users/new_sso_user/`, {
            method: 'POST',
            body: JSON.stringify({ sso_username: ssoUsername }),
        })
    }
    
    const upsertBudget = (budget: Ref<components['schemas']['BudgetRequest'] | components['schemas']['PatchedBudgetRequest']>, pk?: number) => {
        const fetchPath = !pk ? '' : pk + '/'

        return preconfiguredFetch<components['schemas']['BudgetReply']>(`/api/budgets/${fetchPath}`, {
            method: !pk ? 'POST' : 'PATCH',
            body: JSON.stringify(budget.value),
        })
    }

    return {
        createSSOUser,
        upsertBudget,
        getYearBudgetDetails,
        getEvents,
        getBookings,
        getBudgetDetails,
        getFond,
        getPotentialParents,
        getAnnualPlan,
        getRequesters,
        getBudgets,
        getLoggedInUser,
        getRecentInvoices,
        getRecentRequests,
        getStats,
        getStaleRequests,
        getStaleInvoices,
    }
}
