import type { VueQueryPluginOptions } from '@tanstack/vue-query'
import type { App } from 'vue'
import { VueQueryPlugin } from '@tanstack/vue-query'

const vueQueryPluginOptions: VueQueryPluginOptions = { queryClientConfig: { defaultOptions: { queries: { refetchOnWindowFocus: false } } } }

export default {
    install(app: App) {
        app.use(VueQueryPlugin, vueQueryPluginOptions)
    }, 
}
