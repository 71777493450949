<template>
    <div>
        <div class="mb-5">
            Haushaltsjahr:
            <PDropdown
                v-model="selectedYear"
                class="ml-5"
                :options="yearOptions"
            />
        </div>
        <Suspense>
            <template #default>
                <BaseCard>
                    <AnnualPlan :year="selectedYear" />
                </BaseCard>
            </template>
            <template #fallback>
                Loading...
            </template>
        </Suspense>
    </div>
</template>

<script setup lang="ts">
import {
    ref, computed, 
} from 'vue'

const selectedYear = ref(new Date().getFullYear())

const yearOptions = computed(() => {
    const years = [];
    for (let i = 2019; i <= new Date().getFullYear(); i++) {
        years.push(i)
    }
    return years
})
</script>
